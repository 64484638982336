<template>
  <div v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <el-row :gutter="20" v-if="getRoleSlug === 'superadmin'">
           <!-- radio  -->
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="For" >
              <br>
                <el-radio-group v-model="radio" @change="refresh" >
                    <el-radio-button  label=admin>Admin</el-radio-button>
                    <el-radio-button label="broker">Broker</el-radio-button>
                    <el-radio-button label="client">Client</el-radio-button>
                </el-radio-group>
              </el-form-item>
          </el-col>
       
            <!-- admin  -->
            <el-col v-if="radio == 'admin' " :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.admin_selection"
                label="Admin Selection"
                prop="admin_selection"
                :label-width="formLabelWidth"
              >
                <el-select
                  v-model="formData.admin_selection"
                  clearable
                  filterable
                  placeholder="Select"
                >
                <el-option label="Select" value=""> </el-option>
                  <el-option label="All" value="all"> </el-option>
                  <el-option label="Select Admin" value="select_admin">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col v-else-if="radio == 'broker' || radio == 'client' " :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
             <el-form-item
                :error="formErrors.admin"
                label="Admin"
                prop="admin"
                :label-width="formLabelWidth"
              >
                <el-select  filterable v-model="formData.admin" @change="handleAdminBrokerAndClientList" placeholder="Select">
                  <el-option label="Select" value=""> </el-option>
                  <el-option
                     v-for="item in listAdmins"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!-- admin dropdown  -->
          <el-col  v-if="formData.admin_selection === 'select_admin' && radio == 'admin'" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item
              :error="formErrors.admin_recipient"
              label="Admin Recipient"
              prop="admin_recipient"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="formData.send_to"
                multiple
                filterable
                placeholder="Select"
              >
                <el-option
                  v-for="item in listAdmins"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- select broker  -->
          <el-col v-if="radio == 'broker'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.broker_selection"
              label="Broker Selection"
              prop="broker_selection"
              :label-width="formLabelWidth"
            >
            <!-- @change="handleSelectBroker" -->
              <el-select
                
                v-model="formData.broker_selection"
                clearable
                filterable
                placeholder="Select"
              >
                <el-option label="Select" value=""> </el-option>
                <el-option label="All" value="all"> </el-option>
                <el-option label="Select Broker" value="select_broker">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- <el-col v-if="radio == 'broker' || radio == 'client' " :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
             <el-form-item v-if="getRoleSlug === 'superadmin'"
                :error="formErrors.admin"
                label="Admin"
                prop="admin"
                :label-width="formLabelWidth"
              >
                <el-select v-model="formData.admin" @change="handleAdminBrokerAndClientList" placeholder="Select">
                  <el-option
                     v-for="item in listAdmins"
                      :key="item.master"
                      :label="item.name"
                      :value="item.master"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
          </el-col> -->
          <!-- broker dropdown  -->
          <el-col  v-if="formData.broker_selection === 'select_broker'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.recipient"
              label="Recipient Broker"
              prop="recipient"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="formData.send_to"
                multiple
                filterable
                placeholder="Select"
              >
                <el-option
                  v-for="item in listDataDropdownBrokers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
      
          <!-- select client  -->
          <el-col v-if="radio == 'client'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.client_selection"
              label="Client Selection"
              prop="client_selection"
              :label-width="formLabelWidth"
            >
            <!-- @change="handleSelect" -->
              <el-select 
                v-model="formData.client_selection"
                clearable
                filterable
                placeholder="Select"
              >
              <el-option label="Select" value=""> </el-option>
                <el-option label="All" value="all"> </el-option>
                <el-option label="Select Client" value="select_client">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- client dropdown  -->
          <el-col v-if="formData.client_selection === 'select_client'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.recipient"
              label="Recipient Client"
              prop="recipient"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="formData.send_to"
                multiple
                filterable
                placeholder="Select"
              >
                <el-option
                  v-for="item in listDataDropdownClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
          
  
         <!-- title  -->
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item
                :error="formErrors.title"
                label="Title"
                prop="title"
                :label-width="formLabelWidth"
              >
                <el-input v-model="formData.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
   
      
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item
              :error="formErrors.message"
              label="Message"
              prop="message"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                v-model="formData.message"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Cancel</el-button>
        <el-button type="info" @click="sendChildFormData()">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";
import { fetchClientList, fetchBrokersList } from "@/api/market-watch";
import { fetchAllAdminList, adminBrokers, adminClients } from "@/api/super-admin/admin";
import { mapGetters } from "vuex";
import getRoleSlug from "@/store/app.js";



export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
  
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
      listDataDropdownClients: [],
      listDataDropdownBrokers:[],
      listAdmins: {},
      formData:{},
      radio: ''
    };
  },
  computed: {
     ...mapGetters(["getRoleSlug"]),
    dialogTitle() {
      return this.dialogType === "create"
        ? "Add Notification"
        : "Edit Notification";
    },
  },
  created() {
    this.formRules.title = requiredRule("title");
    this.formRules.client_selection = requiredRule("client_selection");
    this.formRules.message = requiredRule("message");
    if(getRoleSlug.state.roleSlug == 'admin' || getRoleSlug.state.roleSlug == 'broker') {
      this.radio = "client"
      this.getClientList();
      this.getBrokerList();
    } 
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.getAdminList();
    } 
  },
  methods: {
    
    handleSelect(value) {
      console.log(value)
      let send_to = [];
      this.$set(this.formData,'send_to', send_to)
      // if (value == "all") {
      //   this.listDataDropdownClients.forEach((item) => {
      //     send_to.push(item.id);
      //   });
      // } 
      // this.$set(this.formData,'send_to', send_to)
    },
    handleSelectBroker(value) {
      console.log(value)
      let send_to = [];
      this.$set(this.formData,'send_to', send_to)
      // if (value == "all") {
      //   this.listDataDropdownBrokers.forEach((item) => {
      //     send_to.push(item.id);
      //   });
      // } 
      // this.$set(this.formData,'send_to', send_to)
    },
    handleSelectClient(value) {
      console.log(value)
      let send_to = [];
      this.$set(this.formData,'send_to', send_to)
      // if (value == "all") {
      //   this.listAdmins.forEach((item) => {
      //     send_to.push(item.id);
      //   });
      // } 
      // this.$set(this.formData,'send_to', send_to)
    },
    refresh() {
      console.log('refrehs', this.formData)
      this.formData = {...this.formData, send_to:[]}
      if(this.radio == "client"){
        this.formData = {...this.formData, client_selection:''}
        this.formData = {...this.formData, admin:''}
      }else if(this.radio == "broker"){
        this.formData = {...this.formData, broker_selection:''}
        this.formData = {...this.formData, admin:''}
      }else if(this.radio == "admin"){
        this.formData = {...this.formData, admin_selection:''}
      }

      console.log('refrehs')
      //this.formData = {}
    },
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      this.loading = true;
      //   console.log(this.formData)
      this.formData.send_role = this.radio
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          if (this.dialogType === "edit") {
            this.formData = Object.assign(this.formData, {
              dialog_type: this.dialogType,
            });
          }
          this.$emit("getChildFormData", this.formData);
          this.loading = false;
        }
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    getClientList() {
      this.loading = true;
      fetchClientList().then((response) => {
        this.listDataDropdownClients = response.data.data;
      });
    },
    getBrokerList() {
      this.loading = true;
      fetchBrokersList().then((response) => {
        this.listDataDropdownBrokers = response.data.data;
      });
    },
    handleAdminBrokerAndClientList(id) {
      if(this.radio == "broker"){
        this.handleAdminBrokerList(id)
      }else{
        this.handleAdminClientList(id)
      }
    },
    handleAdminBrokerList(id) {
      adminBrokers(id)
        .then((response) => {
          if (response.data.success === true) {
            this.listDataDropdownBrokers = response.data.data;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    handleAdminClientList(id) {
      adminClients(id).then((response) => {
          if (response.data.success === true) {
            this.listDataDropdownClients = response.data.data;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
      })
      .catch((err) => {
        console.log(err)
      });
    }
  },
};
</script>
